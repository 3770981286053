window.addEventListener('load', function() {
	var $window = $(window);
	var $body = $(document.body);
	// $('.mask-tel').mask("(99) 99999-9999");

	/**
	 * Setup do token de validação no cabeçalho
	 */
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
    
    $('#carousel-home').slick({
        dots: true,
        slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
        autoplay: false,
        autoplaySpeed: 1000,
		pauseOnHover: false,
		
		responsive: [
			{
				breakpoint: 960,
				settings: {
					arrows: true,
					dots: false,
					nextArrow:'#next-carousel',
					prevArrow:'#prev-carousel',
			  	}
			},
		  ]
	});    
	
	$('#box-slick-text').slick({
		slidesToShow: 1,
		slidesToScroll: 1,		
		autoplay: false,
		fade:true,
		autoplaySpeed: 2000,			
		arrows: false,
		infinite:true,
		dots: true,

		responsive: [
			{
				breakpoint: 960,
				settings: {
					arrows: true,
					dots: false,
					autoplay: false,
					infinite: false,
					nextArrow:'#next-carousel',
					prevArrow:'#prev-carousel',
			  	}
			},
		  ]
	});
	

	$('#slider-home').slick({
        dots: false,
        slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		nextArrow:'#next-home',

		responsive: [
			{
				breakpoint: 480,	
				settings:{
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		  ]
	});

	$('#1').click(function() {
		$('#conteudo1').addClass('active');
		$('#conteudo2').removeClass('active');
		$('#conteudo3').removeClass('active');
		$('#conteudo4').removeClass('active');
	});
	$('#2').click(function() {
		$('#conteudo1').removeClass('active');
		$('#conteudo2').addClass('active');
		$('#conteudo3').removeClass('active');
		$('#conteudo4').removeClass('active');
	});
	$('#3').click(function() {
		$('#conteudo1').removeClass('active');
		$('#conteudo2').removeClass('active');
		$('#conteudo3').addClass('active');
		$('#conteudo4').removeClass('active');
	});
	$('#4').click(function() {
		$('#conteudo1').removeClass('active');
		$('#conteudo2').removeClass('active');
		$('#conteudo3').removeClass('active');
		$('#conteudo4').addClass('active');
	});
	
	$('#slider-santa-mix').slick({
        dots: false,
        slidesToShow: 2,
		slidesToScroll: 1,
		arrows: true,
		nextArrow:'#proximo',

		responsive: [
			{
				breakpoint: 480,	
				settings:{
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		  ]
	});   

  
	
	$('#slider-stp-protec').slick({
        dots: false,
        slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
        autoplay: false,
        autoplaySpeed: 1000,
		pauseOnHover: true,
		nextArrow:'#seguinte',

		responsive: [
			{
				breakpoint: 960,	
				settings:{
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,	
				settings:{
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		  ]
	});    

	$('#slider-produtos').slick({
        dots: true,
		slidesToScroll: 1,
		arrows: true,
        autoplay: false,
        autoplaySpeed: 9000,
		pauseOnHover: false,
		nextArrow:'#next',
		prevArrow:'#prev',
	});   

	$('#slider-produtos-mobile').slick({
        dots: true,
		slidesToScroll: 1,
		arrows: true,
        autoplay: false,
        autoplaySpeed: 9000,
		pauseOnHover: false,
		nextArrow:'#xx',
		prevArrow:'#yy',
	}); 

	$('#slider-for').slick({
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '#slider-mapas'
	  });

	$('#slider-mapas').slick({
        dots: false,
        slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
        autoplay: false,
        autoplaySpeed: 9000,
		pauseOnHover: false,
		nextArrow:'#mais',
		asNavFor: '#slider-for'
	});   

	$('.slider-nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		//asNavFor: '.slider-for',
		dots: true,
		centerMode: true,
		focusOnSelect: true,
		nextArrow:'#mais',
	  });

	$('#1').click(function() {
		$('#mapa1').addClass('active');
		$('#mapa2').removeClass('active');
		$('#mapa3').removeClass('active');
		$('#mapa4').removeClass('active');
		$('#mapa5').removeClass('active');
		$('#mapa6').removeClass('active');
		$('#mapa7').removeClass('active');
	});
	$('#2').click(function() {
		$('#mapa1').removeClass('active');
		$('#mapa2').addClass('active');
		$('#mapa3').removeClass('active');
		$('#mapa4').removeClass('active');
		$('#mapa5').removeClass('active');
		$('#mapa6').removeClass('active');
		$('#mapa7').removeClass('active');
	});
	$('#3').click(function() {
		$('#mapa1').removeClass('active');
		$('#mapa2').removeClass('active');
		$('#mapa3').addClass('active');
		$('#mapa4').removeClass('active');
		$('#mapa5').removeClass('active');
		$('#mapa6').removeClass('active');
		$('#mapa7').removeClass('active');
	});
	$('#4').click(function() {
		$('#mapa1').removeClass('active');
		$('#mapa2').removeClass('active');
		$('#mapa3').removeClass('active');
		$('#mapa4').addClass('active');
		$('#mapa5').removeClass('active');
		$('#mapa6').removeClass('active');
		$('#mapa7').removeClass('active');
	});
	$('#5').click(function() {
		$('#mapa1').removeClass('active');
		$('#mapa2').removeClass('active');
		$('#mapa3').removeClass('active');
		$('#mapa4').removeClass('active');
		$('#mapa5').addClass('active');
		$('#mapa6').removeClass('active');
		$('#mapa7').removeClass('active');
	});
	$('#6').click(function() {
		$('#mapa1').removeClass('active');
		$('#mapa2').removeClass('active');
		$('#mapa3').removeClass('active');
		$('#mapa4').removeClass('active');
		$('#mapa5').removeClass('active');
		$('#mapa6').addClass('active');
		$('#mapa7').removeClass('active');
	});
	$('#7').click(function() {
		$('#mapa1').removeClass('active');
		$('#mapa2').removeClass('active');
		$('#mapa3').removeClass('active');
		$('#mapa4').removeClass('active');
		$('#mapa5').removeClass('active');
		$('#mapa6').removeClass('active');
		$('#mapa7').addClass('active');
	});


	$("#atuacao").on("click", function(){
		$("html, body").animate({
			scrollTop: $($("#area-atuacao")).offset().top
		}, 700);
	});

/**
 * Objeto público com  métodos usados ao longo do app
 */

var speedometer = false;
var biAlready = false;
var miAlready = false;
var percentAlready2 = false;
var lastScrollTopH = 0;
var lastScrollTopA = 0;
var currentElemActive = 0;

var APP = {
	init: function () {
		console.log("JS init");
		var _this = this;
        _this.binds();
        _this.initSliders();
	},

	binds: function(){
		$("#hamburguer-btn, #hamburguer-btn-mobile").on("click", function(e){
			$("html, body").toggleClass("menu-mobile-active");

			if ($("html, body").hasClass("menu-mobile-active")) {
				$("#wrapper-menu-mobile").css("display", "block");
			} else {
				setTimeout(function() {
					$("#wrapper-menu-mobile").css("display", "none");
				}, 200);
			}
		});

		$(".btn-go-to-top").on("click", function(){
			$("html, body").animate({
				scrollTop: $($("header.header")).offset().top
			}, 700);
		});

		$(".scroll-to-target").on("click", function(){
			var target = $(this).data("target");

			$("html, body").animate({
				scrollTop: $($("#"+target)).offset().top
			}, 600);
		});
		
	

		$(".go-to-slide").on("click", function(){
			var carousel = $(this).data("carousel");
			var target = $(this).data("target");

			$(carousel).slick('slickGoTo', target);

			if ($(window).innerWidth() > 991) {
				if ($('#metodologias-carousel')) {
					$("html, body").animate({
						scrollTop: $($(".slide-section-" + target)).offset().top
					}, 0);
				}
			}
		});

		$(".toggle-modal").on("click", function(){
			var modal = $(this).data("modal");
			var video = $(this).data("video");

			APP.toggleModal(modal, video);
		});
		
		$(".btn-toggle-mobile").on("click", function(){
			APP.openMobileMenu();
		});

		$('#metodologias-carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			event.preventDefault();
			var navigation = $(this).data("navigation");

			$('#'+navigation+' button').removeClass("active");
			$('#'+navigation+' button[data-target="'+ nextSlide +'"]').addClass("active");
		});

		$(window).on("scroll", function(){
			APP.hideUpBtn();
			APP.watchAnimations();
			APP.watchSlidesCustomer();
			APP.watchHeader();
			APP.watchFocusElements();
		});
		
		$(document).ready(function() {
			APP.hideUpBtn();
			APP.watchAnimations();
			APP.watchSlidesCustomer();
		});
	},

	initSliders: function () {
		if ($("#metodologias-carousel")) {
			$('#metodologias-carousel').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				fade: true,
				dots: false,
				// appendDots: "$('#metodologias-nav')"
			});
		}

		if ($("#testmonials-carousel")) {
			$('#testmonials-carousel').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				fade: true,
				dots: false,
				infinite: false,
				// appendArrows: '.carousel-arrows',
				prevArrow: $('.carousel-arrows .slick-prev'),
				nextArrow: $('.carousel-arrows .slick-next')
			});
		}
	},

	hideUpBtn: function () {
    if ($(".btn-go-to-top")) {
      var doc = document.documentElement;
      var screenTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      if (screenTop >= 400) {
        $(".btn-go-to-top").addClass("btn-show");
      } else {
        $(".btn-go-to-top").removeClass("btn-show");
      }
    }
	},

	playPauseVideo: function(video) {
		var mediaVideo = $("#"+video).get(0);

		if (mediaVideo.paused) {
			console.log("PLAY!!!");
			mediaVideo.play();
		} else {
			console.log("PAUSE!!!");
			mediaVideo.pause();
		}
	},

	toggleModal: function(modal, video) {
		var mediaVideo = $("#"+video).get(0);

		if ($("html, body").hasClass("modal-active")) {
			$("html, body").removeClass("modal-active");
			$("#"+modal).removeClass("modal-active");

			if (mediaVideo) {
				mediaVideo.pause();
			}
		} else {
			$("html, body").addClass("modal-active");
			$("#"+modal).addClass("modal-active");

			if (mediaVideo) {
				mediaVideo.play();
			}
		}
	},

	closeModal: function(modal) {
		$("html, body").removeClass("modal-active");
		$("#"+modal).removeClass("modal-active");
	},
    
	openMobileMenu: function(){
		if(!$('body').hasClass('sub-menu-aberto-body')){
			$('body, html').toggleClass('menu-mobile-open');

			if( $('body, html').hasClass('menu-mobile-open') ){
				$('.btn-toggle-mobile').addClass('menu-collapsed');
				$("#hamburger-btn, #hamburger-btn-fixed").removeClass("to-arrow");
				$("#hamburger-btn, #hamburger-btn-fixed").addClass("from-arrow");
				$('html').css('overflow-y', 'hidden');
			} else {
				$('.btn-toggle-mobile').removeClass('menu-collapsed');
				$("#hamburger-btn, #hamburger-btn-fixed").addClass("to-arrow");
				$("#hamburger-btn, #hamburger-btn-fixed").removeClass("from-arrow");
				$('html').css('overflow-y', 'inherit');
			}
		}
	},

	watchHeader: function() {
		var st = window.pageYOffset || document.documentElement.scrollTop;

		// SE SCROLLANDO PARA CIMA
		if (st < lastScrollTopH){
			if ($(window).scrollTop() > ($(window).height() / 1.5)) {
				// SE SCROLLANDO PARA CIMA & NÃO NA PRIMEIRA SEÇÃO
				$("#header-fixed").addClass("header-visible");
			} else {
				// SE SCROLLANDO PARA CIMA NA PRIMEIRA SEÇÃO
				$("#header-fixed").removeClass("header-visible");
			}
		} else {
		// SE SCROLLANDO PARA BAIXO
			if ($(window).scrollTop() > ($(window).height() / 1.5)) {
				// SE SCROLLANDO PARA BAIXO & NÃO NA PRIMEIRA SEÇÃO
				$("#header-fixed").removeClass("header-visible");
			}
		}

		lastScrollTopH = st <= 0 ? 0 : st;
	},

	watchSlidesCustomer: function() {
		if ($("#metodologias").length) {
			$(".slide-section").each(function(i) {
				if (APP.isOnScreen($(".slide-section-"+i))) {
					$("#metodologias-carousel").slick('slickGoTo', i);
				}
			});
		}
	},

	watchAnimations: function() {
		if (APP.isOnScreen($("#animated-charts"), "center")) {
			$("#animated-charts").addClass("animate");
			
			if (!$("#animated-charts").hasClass("count-increased")) {
				$("#animated-charts").addClass("count-increased");

				$(".increase-percent").each(function(i) {
					APP.increaseCount($(this)[0].id, 0, $(this).data("value"));
				});
			}
		} else {
			$("#animated-charts").removeClass("animate");
			
			if ($("#animated-charts").hasClass("count-increased")) {
				$("#animated-charts").removeClass("count-increased");

				$(".increase-percent").each(function(i) {
					APP.decreaseCount($(this)[0].id, 0, $(this).data("value"));
				});
			}
		}
	},

	increaseCount: function(id, count, value) {
		var timeout = (1 / value) * 1000;

		$("#"+id).text(count);

		if (count < value) {
			setTimeout(() => {
				APP.increaseCount(id, count + 1, value);
			}, timeout);
		}
	},

	decreaseCount: function(id, count, value) {
		var timeout = (1 / value) * 1000;

		$("#"+id).text(count);

		if (count > value) {
			setTimeout(() => {
				APP.increaseCount(id, count - 1, value);
			}, timeout);
		}
	},

	watchFocusElements: function() {
		var arr = ["0", "1", "2", "3", "4"];

		arr.map((item) => {
			if (APP.isOnScreen($(".content-focus-" + item), "top")) {
				var filteredArr = arr.filter(function (val) { return parseInt(item) != parseInt(val) });
				var items = $(".content-focus-" + item).data("items");

				$(filteredArr).map((_index, value) => {
					$("#chart-" + value).removeClass("active");
					
					var respecItems = $(".content-focus-" + value).data("items");
					$(respecItems).map((_index, value) => {
						$("#how-we-help-" + value).removeClass("active");
					});
				});
				
				if (!$("#chart-" + item).hasClass("active")) {
					$("#chart-" + item).addClass("active");
				}

				$(items).map((_indexb, valueb) => {
					if (!$("#how-we-help-" + valueb).hasClass("active")) {
						$("#how-we-help-" + valueb).addClass("active");
					}
				});
			}
		});
	},

	isOnScreen: function(element, anchor) {
		if (element.offset()) {
			var elemPos = element.offset().top;
			var elemHeight = element[0].offsetHeight;

			var elemTop = (elemPos + elemHeight) - $(window).scrollTop() - 100;

			if (anchor === "top") {
				var elemTop = elemPos - $(window).scrollTop() + 400;
			} else if (anchor === "bottom") {
				var elemTop = elemPos - $(window).scrollTop();
			}

			var screenHeight = $(window).height();
			var isOnTop = (elemTop > screenHeight) ? false : true;

			if (isOnTop) {
				var isOnTop = ((elemPos + elemHeight) > $(window).scrollTop());
			}

			return isOnTop;
		}
	},

	validaCampoEmail: function(element) {
		var value = element.value;
		var regex = /^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/;

		if (regex.test(value)) {
				$(element).removeClass('error');
				return;
		} else {
			$(element).addClass('error');
			
			Swal.fire({
				heightAuto: false,
				icon: 'error',
				title: "E-mail informado inválido!",
				text: "Ex: exemplo@exemplo.com",
			});
		}
	},

	validaForm: function(form){
		var _this = this;

		var required = form.find('.required');
		var select = form.find('select.required');
		var requiredSelect = form.find('select.required');
		var aceite = form.find('.aceite');
		var requiredAceite = form.find('.aceite .required:checked');
		var req = 0;
		var reqSel = 0;
		var reqAc = 0;
		var classe;

		for(i=0;i<required.length;i++){
			if($(required[i]).val() == "" && $(required[i]).prop('disabled') != true){
				classe = $(required[i]).attr('class');
				
				if(classe.search("required") != -1){	
					$(required[i]).addClass('error');
				}
				req = 1;
			}else{
				classe = $(required[i]).attr('class');
				
				if(classe.search("error") != -1){
					$(required[i]).removeClass('error');
				}
			}
		}

		if(select.length > 0){
			for(i=0;i<requiredSelect.length;i++){
				if($(requiredSelect[i]).find(":selected").text() == "Selecione"){
					classe = $(requiredSelect[i]).attr('class');
					
					if(classe.search("required") != -1){	
						$(requiredSelect[i]).addClass('error');
					}
					reqSel = 1;
				}else{
					classe = $(requiredSelect[i]).attr('class');
					
					if(classe.search("error") != -1){
						$(requiredSelect[i]).removeClass('error');
					}
				}
			}
		}

		if(aceite.length > 0){
			_this.projetoAceite.removeClass('error');
			if(requiredAceite.length == 0){
				_this.projetoAceite.addClass('error');
				reqAc = 1;
			}else{
				_this.projetoAceite.removeClass('error');
			}
		}
		
		if(req == 1 || reqSel == 1 || reqAc == 1){
			return false;
		}else if(req == 0 && reqSel == 0 && reqAc == 0){
			return true;
		}
	},

	enviarContato: function(id){
		if(this.validaForm($('#form-contato'))){
		  $('#btn-enviar-contato').text('Enviando');
		  var dados = $("#"+id).serialize();
		  jQuery.ajax({
			type: "POST",
			url: base_url+'/envia-contato',
			data: dados,
			success: function(msg) {
			  if (msg == 'success'){
				document.getElementById(id).reset();
				$('#btn-enviar-contato').text('Enviado');
							Swal.fire("Enviado com sucesso","","success");
			  }else if(msg == 'error'){
				$('#btn-enviar-contato').text('Enviar');
							Swal.fire("Ocorreu um erro ao enviar","","error");
			  }else if(msg == 'error'){
							$('#btn-enviar-contato').text('Enviar');
							Swal.fire("Captcha não preenchido corretamente","","error");
						}
			}
		  });
		}else{
		  $('#btn-enviar-contato').text('Enviar');
				Swal.fire("Algum campo ficou em branco, por favor reveja e tente novamente","","error");
		}
	}

};

//expondo projeto padrao

	APP.init();

	window.APP = APP;
	
//});


	$('#btn-enviar-contato').on('click', function() {
		if(APP.validaForm($('#form-contato'))){
			$('#btn-enviar-contato').text('Enviando');
			var dados = $("#form-contato").serialize();
			console.log('dados');
			jQuery.ajax({
				type: "POST",
				url: base_url+'/envia-contato',
				data: dados,
				success: function(msg) {
					if (msg == 'success'){
						document.getElementById("form-contato").reset();
						$('#btn-enviar-contato').text('Enviar');
						Swal.fire("Enviado com sucesso","","success");
					}else{
						$('#btn-enviar-contato').text('Enviar');
						Swal.fire("Ocorreu um erro, tente novamente mais tarde","","error");
					}
				}
			});
		}else{
			$('#btn-enviar-contato').text('Enviar');
			Swal.fire("Algum campo ficou em branco, por favor reveja e tente novamente","","error");
		}
	});

	$('#btn-enviarForm').on('click', function() {
		if(APP.validaForm($('#form-fale-conosco'))){
			$('#btn-enviarForm').text('Enviando');
			var dados = $("#form-fale-conosco").serialize();
			console.log('dados');
			jQuery.ajax({
				type: "POST",
				url: base_url+'/envia-fale-conosco',
				data: dados,
				success: function(msg) {
					if (msg == 'success'){
						document.getElementById("form-fale-conosco").reset();
						$('#btn-enviarForm').text('Enviar');
						Swal.fire("Enviado com sucesso","","success");
					}else{
						$('#btn-enviarForm').text('Enviar');
						Swal.fire("Ocorreu um erro, tente novamente mais tarde","","error");
					}
				}
			});
		}else{
			$('#btn-enviarForm').text('Enviar');
			Swal.fire("Algum campo ficou em branco, por favor reveja e tente novamente","","error");
		}
	});



});
